import React from "react"
import styles from "./Footer.module.scss"

export default function Footer() {
  return (
    <footer className={styles.footer}>
      All rights reserved Exercise Express {new Date().getFullYear()}
    </footer>
  )
}
