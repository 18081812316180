import React, { useEffect, useRef, useState } from "react"
import VideoCard from "./components/VideoCard"
import styles from "./Cover.module.scss"

import video1 from "../../../assets/videos/Cover/EE_Videos_Secure.mp4"
import video2 from "../../../assets/videos/Cover/EE_Videos_Personal.mp4"
import video3 from "../../../assets/videos/Cover/EE_Videos_Experienced.mp4"

import poster1 from "../../../assets/images/Cover/EE_Videos_Secure.png"
import poster2 from "../../../assets/images/Cover/EE_Videos_Personal.png"
import poster3 from "../../../assets/images/Cover/EE_Videos_Experienced.png"

const videos = [
  {
    src: video1,
    poster: poster1,
    title: "Secure",
    text:
      "You can get in shape on your own, but it will be easier and safer with Exercise Express.",
  },
  {
    src: video2,
    poster: poster2,
    title: "Personal",
    text:
      "Exercise Express provides a private and personalized approach to working out that is rewarding and fun.    ",
  },
  {
    src: video3,
    poster: poster3,
    title: "Experienced",
    text:
      "I have over 30 years of fitness experience and have learned the best ways to get you where you want to be!",
  },
]

export default function Cover() {
  const [activeVideo, _setActiveVideo] = useState(null)

  const activeVideoRef = useRef(activeVideo)

  const setActiveVideo = video => {
    activeVideo && activeVideo.pause()
    video && video.play()
    activeVideoRef.current = video
    _setActiveVideo(video)
  }

  const outsideClickListener = e => {
    if (!activeVideoRef.current) return
    if (activeVideoRef.current.parentElement.contains(e.target)) return
    activeVideoRef.current.pause()
    setActiveVideo(null)
  }

  useEffect(() => {
    document.addEventListener("click", outsideClickListener)
    return () => document.removeEventListener("click", outsideClickListener)
  }, [])

  return (
    <section className={styles.cover}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.text}>Stay strong, live strong.</div>
          <div className={styles.waves}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none">
              <path d="M0.00,49.99 C205.08,137.46 264.33,-31.27 500.00,49.99 L500.00,0.00 L0.00,0.00 Z"></path>
            </svg>
          </div>
        </div>
        <div className={styles.videos}>
          {videos.map((video, i) => (
            <VideoCard
              video={video}
              key={i}
              activeVideo={activeVideo}
              setActiveVideo={setActiveVideo}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
