import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./Qualifications.module.scss"

export default function Qualifications() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "Qualifications" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className={styles.qualifications}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.waves}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none">
              <path d="M0.00,49.99 C205.08,137.46 342.4,-31.27 500.00,49.99 L500.00,150.00 L0.00,150.00 Z"></path>
            </svg>
          </div>
          <div className={styles.title}>Qualifications</div>
        </div>

        <div className={styles.bottomWrapper}>
          <div className={styles.text}>
            <p>
              Sue has had a love of fitness throughout her life. She developed a
              respect for the human body and all it can do after undergoing open
              heart surgery at the age of 10. Sue was one of the first personal
              fitness trainers in New Hampshire. She founded Exercise Express
              over 30 years ago and remains passionate about providing quality
              service to her clients.
            </p>
            <p className={styles.bold}>
              Sue has a BA in Education from New England College and holds
              International Fitness Certifications through:
            </p>
          </div>
          <div className={styles.logos}>
            {data.allFile.edges.map((logo, i) => (
              <div key={i} className={styles.logoWrapper}>
                <Img fluid={logo.node.childImageSharp.fluid} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
