import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Common/Seo"
import Cover from "../components/Sections/Cover/Cover"
import Studio from "../components/Sections/Studio/Studio"
import Services from "../components/Sections/Services"
import Qualifications from "../components/Sections/Qualifications"
import About from "../components/Sections/About"
import Contact from "../components/Sections/Contact"

export default function Home() {
  return (
    <Layout>
      <SEO title="Home" />
      <Cover />
      <Studio />
      <Services />
      <Qualifications />
      <About />
      <Contact />
    </Layout>
  )
}
