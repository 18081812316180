import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./Studio.module.scss"

export default function Studio() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "Studio" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className={styles.studio} id="studio">
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.waves}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none">
              <path d="M0.00,49.99 C205.08,137.46 342.4,-31.27 500.00,49.99 L500.00,150.00 L0.00,150.00 Z"></path>
            </svg>
          </div>
          <div className={styles.text}>Your new happy place!</div>
        </div>
        <div className={styles.images}>
          {data.allFile.edges.map((image, i) => (
            <div key={i} className={styles.imageWrapper}>
              <Img fluid={image.node.childImageSharp.fluid} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
