import React, { useRef, useState } from "react"
import styles from "./VideoCard.module.scss"

export default function VideoCard({ video }) {
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlay = () => {
    if (isPlaying) videoRef.current.pause()
    else videoRef.current.play()
    setIsPlaying(!isPlaying)
  }

  const videoRef = useRef()

  const { src, poster, title, text } = video

  return (
    <div className={styles.cardWrapper}>
      <div className={`${styles.card} ${isPlaying && styles.cardPlay} `}>
        <div className={`${styles.video} ${isPlaying && styles.videoPlay} `}>
          <video
            ref={videoRef}
            className={isPlaying ? styles.play : styles.stop}
            loop
            muted="muted"
            src={src}
            poster={poster}
            playsInline
          />
        </div>
        <div className={styles.videoTitle}>{title}</div>

        <div
          className={`${styles.bottomText} ${
            isPlaying && styles.bottomTextPlay
          }`}
        >
          {title === "Personal Training" ? (
            text
          ) : (
            <>
              <div>
                Feel free to contact us to arrange a lecture or a workshop for
                your workforce.
              </div>
              <br />
              <div>
                <b>Past lectures/seminars include:</b>
                <ul>
                  <li>NH Schools Celebrate Wellness Conference</li>
                  <li>American College of Sports and Medicine</li>
                  <li>Franklin Regional Hospital</li>
                  <li>New England Academy of Modeling</li>
                  <li>WGIR’s One Minute Workout</li>
                  <li>Exercise Express TV Show</li>
                </ul>
              </div>
            </>
          )}
        </div>
        <button
          className={`${styles.button} ${isPlaying && styles.buttonPlay}`}
          onClick={() => togglePlay()}
        >
          {isPlaying ? "Close" : "Learn more"}
        </button>
      </div>
    </div>
  )
}
