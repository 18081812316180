import React, { useRef } from "react"
import styles from "./VideoCard.module.scss"

export default function VideoCard({ video, activeVideo, setActiveVideo }) {
  const videoRef = useRef()

  const { src, poster, title, text } = video

  const isPlaying = activeVideo === videoRef.current

  return (
    <div className={styles.cardWrapper}>
      <div className={`${styles.card} ${isPlaying && styles.cardPlay} `}>
        <div className={`${styles.video} ${isPlaying && styles.videoPlay} `}>
          <video
            ref={videoRef}
            className={isPlaying ? styles.play : styles.stop}
            loop
            muted="muted"
            src={src}
            poster={poster}
            playsInline
          />
          <div
            className={`${styles.videoTitle} ${
              isPlaying && styles.videoTitlePlay
            }`}
          >
            {title}
            <p onClick={() => setActiveVideo(videoRef.current)}>LEARN MORE</p>
          </div>
        </div>
        <div
          className={`${styles.bottomText} ${
            isPlaying && styles.bottomTextPlay
          }`}
        >
          {text}
        </div>
      </div>
    </div>
  )
}
