import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import styles from "./About.module.scss"

export default function Studio() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "About" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const slides = []

  for (var i = 0; i < data.allFile.edges.length; i += 4) {
    slides.push(data.allFile.edges.slice(i, i + 4))
  }

  return (
    <section className={styles.about} id="about">
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.title}>About Sue</div>
          <p>
            Sue enjoys an active lifestyle, spending time with family and
            friends. She maintaines her fitness so she can enjoy her passions
            such as bicycling, hiking, skiing and gardening.
          </p>
          <p>
            Sue is a volunteer for the Guiding Eyes for the Blind as a puppy
            raiser and has successfully raised 11 pups to date!
          </p>
          <p>
            Knowing first hand the importance of staying strong and healthy, Sue
            has dedicated her life to helping those who wish to do the same!
          </p>
        </div>
        <div className={styles.right}>
          <Carousel
            showIndicators={false}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={4000}
            transitionTime={700}
          >
            {slides.map((slide, i) => (
              <div className={styles.grid} key={i}>
                {slide.map((image, i) => (
                  <div key={i} className={styles.imageWrapper}>
                    <Img fluid={image.node.childImageSharp.fluid} />
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  )
}
