import React from "react"
import styles from "./Contact.module.scss"

export default function Services() {
  return (
    <section className={styles.contact} id="contact">
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.text}>Book your consultation!</div>
          <div className={styles.waves}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none">
              <path d="M0.00,49.99 C205.08,137.46 264.33,-31.27 500.00,49.99 L500.00,0.00 L0.00,0.00 Z"></path>
            </svg>
          </div>
        </div>
        <div className={styles.middleText}>
          Ready to live your best life? Let’s set up a time to chat!
        </div>
        <div className={styles.formWrapper}>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className={styles.inputWrapper}>
              <input type="text" placeholder="First name"></input>
            </div>
            <div className={styles.inputWrapper}>
              <input type="text" placeholder="Last name"></input>
            </div>
            <div className={styles.inputWrapper}>
              <input type="email" placeholder="Email"></input>
            </div>
            <div className={styles.inputWrapper}>
              <input type="text" placeholder="Phone number"></input>
            </div>
            <div className={styles.textareaWrapper}>
              <textarea rows="4" placeholder="Message"></textarea>
              <button className={styles.submitButton} type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}
