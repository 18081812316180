import React from "react"
import Header from "./components/Header"
import Footer from "./components/Footer"
import "@styles/index.scss"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

export default function Layout({ children }) {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}
