import React from "react"
import VideoCard from "./components/VideoCard"
import styles from "./Services.module.scss"

import video1 from "../../../assets/videos/Services/EE_Videos_PersonalTraining.mp4"
import video2 from "../../../assets/videos/Services/EE_Videos_FitnessWorkshops.mp4"

import poster1 from "../../../assets/images/Services/EE_Videos_PersonalTraining.png"
import poster2 from "../../../assets/images/Services/EE_Videos_FitnessWorkshops.png"

const videos = [
  {
    src: video1,
    poster: poster1,
    title: "Personal Training",
    text:
      "Private classes in our home Studio with personalized programs for all ages and levels of fitness. When we train together all the focus will be on your form and technique to keep you injury free while helping you to achieve your goals.",
  },
  {
    src: video2,
    poster: poster2,
    title: "Fitness Workshops",
    text: `Feel free to contact us to arrange a lecture or a workshop for your workforce.
      
      Past lectures/seminars include:
      -NH Schools Celebrate Wellness Conference
      -American College of Sports and Medicine
      -Franklin Regional Hospital
      -New England Academy of Modeling
      -WGIR’s One Minute Workout
      -Exercise Express TV Show
      `,
  },
]

export default function Services() {
  return (
    <section className={styles.services} id="services">
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.text}>Truly personalized fitness.</div>
          <div className={styles.waves}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none">
              <path d="M0.00,49.99 C205.08,137.46 264.33,-31.27 500.00,49.99 L500.00,0.00 L0.00,0.00 Z"></path>
            </svg>
          </div>
        </div>
        <div className={styles.videos}>
          {videos.map((video, i) => (
            <VideoCard video={video} key={i} />
          ))}
        </div>
      </div>
    </section>
  )
}
