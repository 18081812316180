import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./Header.module.scss"

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "EE_Logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [scrolled, setScrolled] = useState(false)

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 100) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <header
      className={`${styles.header} ${scrolled ? styles.headerScrolled : ""}`}
      id="header"
    >
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <a href="#header">
            <Img
              fluid={data.logo.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </a>
        </div>
        <div className={styles.menu}>
          <a href="#studio">Studio</a>
          <span>|</span>
          <a href="#services">Services</a>
          <span>|</span>
          <a href="#about">About</a>
          <span>|</span>
          <a href="#contact">Contact</a>
        </div>
      </div>
    </header>
  )
}
